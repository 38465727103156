<!-- 发现上报页面 -->
<template>
  <div class="">
       <!-- 左边竖线样式写法 -->
       <div class="titleBox">
        <div class="titleLine" style=""></div>
          <div>登记信息</div>
        </div>
    <!-- 登记信息 -->
    <ax-form ref="form1" :formBuilder="formBuilder1"> </ax-form>
      <!-- 左边竖线样式写法 -->
      <div class="titleBox" style="margin-bottom: 5px;">
        <div class="titleLine" ></div>
          <div>事件基本信息</div>
        </div>
    <!-- 事件基本信息 -->
    <ax-form ref="form2" :formBuilder="formBuilder2">
      <!-- 所属网格 -->
      <div slot="gridName">
        <a-cascader
          :options="options"
          placeholder="请选择所属网格"
          change-on-select
          @change="onChange"
          allowClear
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          v-decorator="[
            'options',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
      <!-- 发生时间 -->
      <div slot="eventTime">
        <a-date-picker
          :value="startValue"
          :disabled-date="disabledDate"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="请选择日期"
          @ok="ok"
          show-time
          v-decorator="[
            'eventTime',
            {
              initialValue: moment(),
              rules: [{ required: true, message: '请选择发生时间' }],
            },
          ]"
        />
      </div>
      <!-- 涉及人员 -->
      <div slot="personName" style="display: flex">
        <a-input disabled :value="personName" placeholder="请选择"></a-input>
        <ax-button @click="choosePeople" style="margin-left: 2%"
          >选择</ax-button
        >
      </div>
      <div slot="eventPicture">
        <imageUpload ref="img1" :businessId="businessId"></imageUpload>
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end; margin-top: 2%">
      <ax-button style="margin-right: 1%" @click="resetFields">取消</ax-button>
      <ax-button @click="submit">上报</ax-button>
    </div>

    <!-- 选择涉事人员 -->
    <choose-people
      ref="choosePeople"
      @receive="receivePeopleId"
      :personInfo="personInfo"
    ></choose-people>
  </div>
</template>

<script>
const formList1 = [
  // {
  //   label: "登记编号",
  //   type: "",
  //   model: "registrantId",
  //   options: { allowClear: true, placeholder: "登记编号" },
  //   col: { span: 6 },
  // },
  {
    label: "登记人",
    type: "",
    model: "registrant",
    options: { placeholder: "登记人", maxLength: 18 },
    col: { span: 6 },
  },
  {
    label: "联系方式",
    type: "",
    model: "phone",
    options: { placeholder: "联系方式" },
    col: { span: 6 },
  },
  {
    label: "登记时间",
    type: "",
    model: "time",
    options: {
      placeholder: "登记时间",
    },
    col: { span: 6 },
  },
];
const formList2 = [
  {
    label: "事件名称",
    type: "",
    model: "eventName",
    options: { allowClear: true, placeholder: "请输入", maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入事件名称" }],
  },
  {
    label: "所属网格",
    type: "",
    model: "gridName",
    options: { placeholder: "请选择" },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择所属网格" }],
  },
  {
    label: "发生时间",
    type: "",
    model: "eventTime",
    options: {},
    col: { span: 12 },
  },
  {
    label: "处理期限",
    type: "",
    model: "handleDeadline",
    options: {
      disabled: true,
      placeholder: "请先选择发生时间",
    },
    col: { span: 12 },
  },
  {
    label: "事件类别",
    type: "select",
    model: "eventCategory",
    options: {
      placeholder: "请选择",
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择事件类别" }],
  },
  {
    label: "事件来源",
    type: "select",
    model: "eventSource",
    options: {
      placeholder: "请选择",
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择事件来源" }],
  },
  {
    label: "事件规模",
    type: "select",
    model: "eventScale",
    options: {
      placeholder: "请选择",
    },
    col: { span: 12 },
  },
  {
    label: "事件等级",
    type: "select",
    model: "eventLevel",
    options: {
      placeholder: "请选择",
    },
    col: { span: 12 },
  },
  {
    label: "涉及人员",
    type: "select",
    model: "personName",
    options: {
      placeholder: "请选择",
    },
    col: { span: 12 },
  },
  {
    label: "事件描述",
    type: "textarea",
    model: "eventDescription",
    options: {
      placeholder: "请输入",
      minRows: 4,
      maxLength: 1000,
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入事件描述" }],
  },
  {
    label: "相关图片",
    type: "",
    model: "eventPicture",
    options: {
      placeholder: "请输入",
    },
    col: { span: 12 },
  },
  {
    label: "事件位置",
    type: "",
    model: "eventPosition",
    options: {
      placeholder: "请输入",
      maxLength: 200,
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入事件位置" }],
  },
];
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
import { mapGetters } from "vuex";
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";
import ChoosePeople from "./choosePeople.vue";
import moment from "moment";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { imageUpload, ChoosePeople },
  data() {
    //这里存放数据
    return {
      api,
      moment,
      businessId: "",
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 120, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      eventTime: null, //事件时间
      startValue: null, //开始时间
      personName: "", //涉及人员名字数组
      personNameID: "", //涉及人员ID数组
      personInfo: [], //涉及人员ID数组
      selectedPeopleList: [],
      options: [], //级联选择器数据
      gridName: "",
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapGetters("app", ["userInfo"]),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getDictionsary() {
      // 事件类别
      await api.dictData({ dicKind: "event_category" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("eventCategory", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 事件来源
      await api.dictData({ dicKind: "event_source" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("eventSource", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 事件规模
      await api.dictData({ dicKind: "event_scale" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("eventScale", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 事件等级
      await api.dictData({ dicKind: "event_level" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("eventLevel", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
    },
    // 获取用户信息
    setDefaultInformation() {
      console.log(this.userInfo);
      this.$refs.form1.setFieldsValue({ registrant: this.userInfo.realname });
      this.$refs.form1.setFieldsValue({ phone: this.userInfo.phone });
      this.$refs.form1.setFieldsValue({
        time: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 19)
          .replace("T", " "),
      });
      this.$refs.form2.setFieldsValue({
        handleDeadline: new Date(
          Date.now() + 7 * 24 * 60 * 60 * 1000 + 8 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, 16)
          .replace("T", " "),
      });
    },
    // 打开选择涉及人员弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 获取子组件传递的已选择涉及人员的数据
    receivePeopleId(item) {
      this.personName = item.map((item) => item.residentName);
      this.personInfo = item;
      this.personNameID = item.map((item) => item.id);
      console.log(this.personName);
      console.log(this.personNameID);
    },
    // 禁用的日期
    disabledDate(startValue) {
      if (!startValue) {
        return false;
      }
      return startValue.valueOf() > new Date().valueOf();
    },
    // 格式化时间为 字符串格式
    formatDateTime(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 时间选择器组件的确定按钮事件
    ok(e) {
      console.log(e, "408");
      this.eventTime = this.formatDateTime(e._d);
      console.log(this.eventTime, "411");
      this.$refs.form2.setFieldsValue({
        handleDeadline: this.calculateFutureDate(this.eventTime),
      });
    },
    // 根据输入的事件发生时间计算一星期后的时间
    calculateFutureDate(initialDateStr) {
      // 将输入的时间字符串转换为 Date 对象
      const initialDate = new Date(initialDateStr);
      // 检查输入是否有效
      if (isNaN(initialDate.getTime())) {
        throw new Error("Invalid date string");
      }
      // 计算7天后的日期
      // 注意：这里不直接修改小时、分钟和秒
      const futureDate = new Date(initialDate);
      futureDate.setDate(initialDate.getDate() + 7);

      // 格式化输出的时间字符串
      const year = futureDate.getFullYear();
      const month = String(futureDate.getMonth() + 1).padStart(2, "0");
      const day = String(futureDate.getDate()).padStart(2, "0");
      // 直接使用初始时间的时分秒
      const hours = String(initialDate.getHours()).padStart(2, "0");
      const minutes = String(initialDate.getMinutes()).padStart(2, "0");
      const seconds = String(initialDate.getSeconds()).padStart(2, "0");

      const formattedFutureDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedFutureDate;
    },
    // 获取级联选择器数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器数据改变回调
    onChange(value, e) {
      console.log(value, e);
      if (Array.isArray(value)) {
        this.gridAddress = value.join("/");
        return value.join("/");
      } else {
        console.error("Expected labels to be an array, but got:", value);
        return "";
      }
    },
    // 级联选择器搜索回调
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    resetFields() {
      this.$refs.form1.setFieldsValue({
        time: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 19)
          .replace("T", " "),
      });
      this.$refs.form2.resetFields();
      this.personNameID = [];
      this.personName = [];
    },
    // 表单数据提交
    submit() {
      this.$refs.form1.form.validateFields(async (err, value) => {
        if (err) return;
        console.log(value);
        if (this.$refs.form2) {
          this.$refs.form2.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1);
          });
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.setDefaultInformation();
    this.getDictionsary();
    this.owningGrid();
  },
};
</script>
<style lang='less' scoped>
/deep/.ant-calendar-picker {
  width: 100%;
}
.titleBox{
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
  .titleLine{
    height: 0.09rem;
    width: 0.015rem;
    background-color:#2a5cff; 
    margin-right: 5px;
  }
}
</style>